import { RecordStatus } from "../api/models/RecordStatus";

export const getStattus = (status: RecordStatus) => {
    if (status === RecordStatus.Processing) {
        return "файл обрабатывается";
    }
    if (status === RecordStatus.Available) {
        return "файл доступен";
    }
    return "файл недоступен";
}