import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, Link, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { ReduxState } from "../../redux/store";
import { setExistingParticipants, setNewParticipant, setParticipants } from "../../redux/actions/Chat";
import { env } from "../../env";
import { MicIcon } from "../../components/icons/MicIcon";
import { UnableMicIcon } from "../../components/icons/UnableMicIcon";
import { CameraIcon } from "../../components/icons/CameraIcon";
import { CameraOffIcon } from "../../components/icons/CameraOffIcon";
import { ChatIcon } from "../../components/icons/ChatIcon";
import { PhoneIcon } from "../../components/icons/PhoneIcon";
import { Messenger } from "../../components/Messenger/Messenger";
import { CloseIcon } from "../../components/icons/CloseIcon";
import { ChatSettingsIcon } from "../../components/icons/ChatSettingsIcon";
import { RecordingIcon } from "../../components/icons/RecordingIcon";
import { ArrowLeftIcon } from "../../components/icons/ArrowLeftIcon";
import { ArrowRightIcon } from "../../components/icons/ArrowRightIcon";
import { CameraLoginIcon } from "../../components/icons/CameraLoginIcon";
import { requestsRepository } from "../../api/RequestsRepository";
import { Input } from "../../components/Input/Input";
import { SendIcon } from "../../components/icons/SendIcon";
import { formatDate, timeDiff } from "../../utils/formatDate";
import { ShareIcon } from "../../components/icons/ShareIcon";
import { SCREEN_ID } from "../../api/models/consts";
import { Participant } from "../../api/models/Participant";
import { ParticipantItem } from "../../components/ParticipantItem/ParticipantItem";
import { RecordHistory } from "../../api/models/RecordHistory";
import { useDetectOutsideClick } from "../../hooks/useDetectOutsideClick";
import { routes } from "../../routes";
import { setPage } from "../../redux/actions/Page";
import { WhiteBoard } from "../../components/WhiteBoard/WhiteBoard";
import { WhiteBoardIcon } from "../../components/icons/WiteBoardIcon";
import { CustomSelect } from "../../components/CustomSelect/CustomSelect";
import { CustomButton } from "../../components/CustomButton/CustomButton";

import "./VideoChat.scss";
import {RecordStatus} from "../../api/models/RecordStatus";
import { getStattus } from "../../utils/common";

const kurentoUtils: any = require("./../../lib/kurento-utils");

export const VideoChat = () => {
    const [params, setParams] = useSearchParams();
    const dispatch = useDispatch();

    const {participants, existingParticipatns, newParticipant, newMessagesCount} = useSelector(({chat}: ReduxState) => chat);
    const [isRecording, setIsRecording] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const [showInviteModal, setShowInviteModal] = useState(false);
    const [connection, setConnection] = useState<null | HubConnection>(null);
    const [room, setRoom] = useState<string | null>(params.get('roomId'));
    const [timelimet, setTimelimet] = useState<any>(params.get('timeout') === "true");
    const [showJoin, setShowJoin] = useState(false);
    const [showRoom, setShowRoom] = useState(false);
    const [autoRecording, setAutoRecording] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [showWaitAdmin, setShowWaitAdmin] = useState(false);
    const [showUserInput, setShowUserInput] = useState(false);
    const [token] = useState<string | null>(params.get('sessionId'));
    const [externalUserId] = useState<string | null>(params.get('userId'));
    const [userName, setUserName] = useState<string>("");
    const [mainUser, setMainUser] = useState<string | null>(null);
    const [selectedUser, setSelectedUser] = useState<string | null>(null);
    const [userMediaVideoStream, setUserMediaVideoStream] = useState<any>(null);
    const [settingsUserMediaVideoStream, setSettingsUserMediaVideoStream] = useState<any>(null);
    const [userMediaAudioStream, setUserMediaAudioStream] = useState<any>(null);
    const [microphone, setMicrophone] = useState(false);
    const [camera, setCamera] = useState(false);
    const [showChat, setShowChat] = useState(false);
    const [canStopSharing, setCanStopSharing] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [showBlock, setShowBlock] = useState(1);
    const [blocksCount, setBlocksCount] = useState(1);
    const [inviteUserName, setInviteUserName] = useState("");
    const [showRecords, setShowRecords] = useState(false);
    const [records, setRecords] = useState<RecordHistory[]>([]);
    const [newRecord, setNewRecord] = useState<RecordHistory | null>(null);
    const [changeMicrophoneResponse, setChangeMicrophneResponse] = useState<any>(null);
    const [existingParticipant, setExistingParticipant] = useState<any>(null);
    const [screenSharing, setScreenSharing] = useState<any>(null);
    const [screenStream, setScreenStream] = useState<any>(null);
    const [canStopRecording, setCanStopRecording] = useState(false);
    const [showWhiteBoard, setShowWhiteBoard] = useState(false);
    const [cameras, setCameras] = useState<any[]>([]);
    const [cameraValue, setCameraValue] = useState(null);
    const [settingsCameraValue, setSettingsCameraValue] = useState(null);
    const [showCameraModal, setShowCameraModal] = useState(false);
    const [changeCameraLoader, setChangeCameraLoader] = useState(false);
    
    const ref = useRef<any>();
    useDetectOutsideClick(ref, () => setShowSettings(false));

    useEffect(() => {
        const connect = new HubConnectionBuilder()
            .withUrl(env().REACT_APP_CALL_HUB_URL ?? "")
            .withAutomaticReconnect()
            .build();

        setConnection(connect);
    }, []);

    useLayoutEffect(() => {
        return () => {
            if (connection) {
                connection?.stop().then(() => {
                    setConnection(null);
                });
            }
        }
    }, [connection]);

    useEffect(() => {
        if (connection !== null) {
            connection.start().then(() => {
                checkAccess()
            });
        }
    }, [connection]);

    useEffect(() => {
        if (connection !== null) {
            connection?.on("JoinRoomResponse", (message: any) => {
                if (message.message?.length > 0) {
                    dispatch(setPage(routes.error,
                        {
                            text: message.message
                        }
                    ));
                    setShowJoin(false);
                } else {
                    requestsRepository.RoomApiService.getRecords(message.roomId).then(res => {
                        if (res.success) {
                            setRecords(res.result);
                        }
                    });
                    setShowRoom(true);
                    setAutoRecording(message.autoRecording);
                    setIsAdmin(message.isAdmin);
                    setUserName(message.name);
                    setIsAdmin(message.isAdmin);
                    if (message.id !== SCREEN_ID) {
                        setMainUser(message.id);
                        setSelectedUser(message.id);
                    }
                }
            });
            
            connection?.on("WarningNotification", (message) => {
                if (message.message?.length > 0) {
                    toast.error(message.message);
                }
            })

            connection?.on("StopSession", (message) => {
                leaveRoom(true);
            })

            connection?.on("ScreenSharing", (message: any) => {
                if (message.message?.length > 0) {
                    toast.error(message.message);
                } else {
                    setScreenSharing(message);
                }
            });

            connection?.on("ExistingParticipants", (message: any) => {
                if (message.data?.length > 0) {
                    dispatch(setExistingParticipants(message.data));
                } else {
                    setExistingParticipant(message);
                }
            });

            connection?.on("NewParticipantArrived", (message: any) => {
                dispatch(setNewParticipant(message));
            });

            connection?.on("ChangeWhiteBoardVisibilityResponse", (message: any) => {
                setShowWhiteBoard(message.visible);
            });

            connection?.on("RecordingStart", (message: any) => {
                if (message?.message?.length > 0) {
                    toast.error(message.message);
                    setCanStopRecording(false);
                } else {
                    setIsRecording(true);
                }
            });

            connection?.on("RecordingStop", (message: any) => {
                setIsRecording(false);
                setNewRecord(message.record);
            });

            connection?.on("ChangeMicrophoneResponse", (message: any) => {
                setChangeMicrophneResponse(message);
            });

            connection?.on("StartWaitAdmin", (message: any) => {
                setShowWaitAdmin(true);
                setUserName(message.name);
                if (message.id !== SCREEN_ID) {
                    setMainUser(message.id);
                }
            });

            connection?.on("StopWaitAdmin", (message: any) => {
                setShowWaitAdmin(false);
            });
        }
    }, [connection]);
    
    useEffect(() => {
        if (connection !== null) {
            connection?.on("ParticipantLeft", (message: any) => {
                onParticipantLeft(message);
            });
            
            connection?.on("IceCandidate", (message: any) => {
                addIceCandidate(message);
            });

            connection?.on("ReceiveVideoAnswer", (message: any) => {
                receiveVideoResponse(message);
            });
        }
    }, [connection, participants]);

    const onParticipantLeft = (request: any) => {
        const participant = participants[request.id];
        participant.rtcPeer.dispose();
        delete participants[request.id];
        if (selectedUser === request.id) {
            setSelectedUser(mainUser);
        }
        dispatch(setParticipants(participants));
    }
    
    const addIceCandidate = (message: any) => {
        participants[message.id].rtcPeer.addIceCandidate(message.candidate, function (error: any) {
            if (error) {
                console.error("Error adding candidate: " + error);
                return;
            }
        });
    }

    const receiveVideoResponse = (result: any) => {
        participants[result.id].rtcPeer.processAnswer(result.sdpAnswer, function (error: any) {
            if (error) {
                return console.error(error);
            }
        });
    }

    useEffect(() => {
        if (newParticipant) {
            onNewParticipant(newParticipant);
        }
    }, [newParticipant]);
    
    useEffect(() => {
        if (!showWaitAdmin) {
            join();
        }
    }, [showWaitAdmin]);

    useEffect(() => {
        if (existingParticipant) {
            onExistingParticipants(existingParticipant);
            setExistingParticipant(null);
        }
    }, [existingParticipant]);

    useEffect(() => {
        if (screenSharing) {
            onScreenSharingParticipants(screenSharing);
            setScreenSharing(null);
        }
    }, [screenSharing]);

    useEffect(() => {
        if (newRecord !== null) {
            setRecords([...records, newRecord]);
            setNewRecord(null);
        }
    }, [newRecord]);

    useEffect(() => {
        if (changeMicrophoneResponse) {
            participants[changeMicrophoneResponse.id].isMicOn = changeMicrophoneResponse.isMicOn;
            dispatch(setParticipants(participants));
            setChangeMicrophneResponse(null);
        }
    }, [changeMicrophoneResponse]);

    useEffect(() => {
        if (existingParticipatns.length > 0 && mainUser !== null) {
            onExistingParticipants({id: mainUser, name: userName, data: [...existingParticipatns]});
            dispatch(setExistingParticipants([]));
        }
    }, [existingParticipatns, mainUser]);
    
    useEffect(() => {
        if (!token && mainUser && mainUser !== SCREEN_ID) {
            setParams(createSearchParams({
                roomId: room ?? "",
                userId: mainUser ?? ""
            }));
        }
    }, [mainUser]);

    const checkAccess = () => {
        if (env().REACT_APP_USE_TM_AUTH && (token === null || token?.length === 0 || room === null || room?.length === 0)) {
            setShowRegister(true);
        } else {
            setShowJoin(true);
        }
    }

    const register = () => {
        navigator.mediaDevices?.getUserMedia({audio: true}).then(stream => {
            setUserMediaAudioStream(stream);
            setMicrophone(true);
        }).catch(() => {
            console.warn("Нет доступа к микрофону")
        });
        navigator.mediaDevices?.getUserMedia({video: true})?.then(stream => {
            setUserMediaVideoStream(stream);
            setCamera(true);
            navigator.mediaDevices?.enumerateDevices().then((devices) => {
                const cameras = devices.filter(x => x.kind === "videoinput").map((x, key) => ({
                    label: x.label?.length > 0 ? x.label : `Камера ${key + 1}`,
                    value: x.deviceId
                }));
                setCameras(cameras);
                if (cameras?.length > 0) {
                    changeCameraValue(cameras?.[0]?.value);
                }
            }).catch(() => {
                console.warn("Нет доступа к камере")
            });
        }).catch(() => {
            console.warn("Нет доступа к камере")
        });
        setTimelimet(false);
        setShowJoin(false);
        if (!env().REACT_APP_USE_TM_AUTH && (room === null || room?.length === 0)) {
            requestsRepository.RoomApiService.createRoom().then(res => {
                if (res.success) {
                    setParams(createSearchParams({
                        roomId: res.result,
                        userId: externalUserId ?? "",
                        timeout: ""
                    }));
                    setRoom(res.result);
                    setShowUserInput(true);
                } else {
                    dispatch(setPage(routes.error,
                        {
                            text: res.message ?? ""
                        }));
                }
            });
        } else {
            setParams(createSearchParams({
                roomId: room ?? "",
                userId: externalUserId ?? "",
                timeout: "",
                sessionId: token ?? ""
            }));
            setShowUserInput(true);
        }
    }
    
    const joinByUser = () => {
        setShowUserInput(false);
        sendMessageSignal("JoinRoom", !env().REACT_APP_USE_TM_AUTH ? {
            roomId: room,
            userId: externalUserId,
            isMicOn: microphone,
            isCamOn: !!userMediaVideoStream
        }: {
            sessionId: token,
            roomId: room,
            isMicOn: microphone,
            isCamOn: !!userMediaVideoStream
        });
    }

    const leaveRoom = (timeout = false) => {
        sendMessageSignal("LeaveRoom", null)

        for (let key in participants) {
            participants[key].rtcPeer.dispose();
        }
        dispatch(setParticipants({}));
        setMainUser(null);
        setShowRoom(false);
        setShowJoin(true);
        setParams(createSearchParams({
            roomId: room ?? "",
            userId: mainUser ?? "",
            timeout: timeout ? "true" : "",
            sessionId: token ?? "",
        }));
        window.location.reload();
    }

    const onExistingParticipants = (msg: any) => {
        const constraints = {
            audio: !!userMediaAudioStream,
            video: userMediaVideoStream ? {
                mandatory: {
                    maxWidth: 1920,
                    maxFrameRate: 24,
                    minFrameRate: 24
                }
            } : false
        };
        const participant = new Participant(msg.name, msg.id, microphone);
        participants[msg.id] = participant;
        dispatch(setParticipants(participants));
        setTimeout(() => {
            const video = document.getElementById(`video-${participant.id}`);
            const options = {
                localVideo: video,
                mediaConstraints: constraints,
                audioStream: userMediaAudioStream,
                videoStream: userMediaVideoStream,
                onicecandidate: (candidate: any, wp: any) => onIceCandidate(participant.id, candidate, wp),
                configuration: {iceServers: env().REACT_APP_STUNS_LIST.split(',').map((x: string) => ({url: x, urls: [x]}))}
            }

            participant.rtcPeer = new kurentoUtils.WebRtcPeer.WebRtcPeerSendonly(options,
                function (error: any) {
                    if (error) {
                        return console.error(error);
                    }
                    //@ts-ignore
                    this.videoEnabled = camera;
                    //@ts-ignore
                    this.audioEnabled = microphone;
                    //@ts-ignore
                    this.generateOffer((error: any, offerSdp: any, wp: any) => offerToReceiveVideo(participant.id, error, offerSdp, wp));
                });
            participants[msg.id] = participant;

            dispatch(setParticipants(participants));
            setTimeout(() => {
                msg?.data?.forEach((x: any) => receiveVideo(x.name, x.id, x.isMicOn, x.isCamOn));
            }, 1000);
        }, 10);
    }

    const onScreenSharingParticipants = (msg: any) => {
        const constraints = {
            audio: false,
            video: {
                mandatory: {
                    maxWidth: 1920,
                    maxFrameRate: 48,
                    minFrameRate: 24
                }
            }
        };
        const participant = new Participant(msg.name, msg.id, true);
        participants[msg.id] = participant;
        dispatch(setParticipants(participants));
        setTimeout(() => {
            const video = document.getElementById(`video-${participant.id}`);
            const options = {
                localVideo: video,
                mediaConstraints: constraints,
                videoStream: screenStream,
                onicecandidate: (candidate: any, wp: any) => onIceCandidate(participant.id, candidate, wp),
                configuration: {iceServers: env().REACT_APP_STUNS_LIST.split(',').map((x: string) => ({url: x, urls: [x]}))}
            }

            participant.rtcPeer = new kurentoUtils.WebRtcPeer.WebRtcPeerSendonly(options,
                function (error: any) {
                    if (error) {
                        return console.error(error);
                    }
                    //@ts-ignore
                    this.generateOffer((error: any, offerSdp: any, wp: any) => offerToReceiveVideo(participant.id, error, offerSdp, wp));
                });
            participants[msg.id] = participant;

            dispatch(setParticipants(participants));
        }, 10);
    }

    const onNewParticipant = (request: any) => {
        receiveVideo(request.name, request.id, request.isMicOn, request.isCamOn);
    }

    const receiveVideo = (sender: any, senderId: any, isMicOn: any, isCamOn: any) => {
        const participant: any = new Participant(sender, senderId, isMicOn);
        participants[senderId] = participant;
        setBlocksCount(Math.ceil(Object.keys(participants).length / 9));
        dispatch(setParticipants(participants));
        if (mainUser === selectedUser) {
            setSelectedUser(senderId);
        }
        setTimeout(() => {
            const video = document.getElementById(`video-${participant.id}`);

            const options = {
                remoteVideo: video,
                mediaConstraints: {audio: SCREEN_ID === sender ? false : true, video: SCREEN_ID === sender ? true : isCamOn},
                onicecandidate: (candidate: any, wp: any) => onIceCandidate(participant.id, candidate, wp),
                configuration: {iceServers: env().REACT_APP_STUNS_LIST.split(',').map((x: string) => ({url: x, urls: [x]}))}
            }

            participant.rtcPeer = new kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(options,
                function (error: any) {
                    if (error) {
                        return console.error(error);
                    }
                    //@ts-ignore
                    this.generateOffer((error: any, offerSdp: any, wp: any) => offerToReceiveVideo(participant.id, error, offerSdp, wp));
                });
            participants[senderId] = participant;
            dispatch(setParticipants(participants));

        }, 10);
    }

    const offerToReceiveVideo = (id: any, error: any, offerSdp: any, wp: any) => {
        if (error) {
            return console.error("sdp offer error");
        }
        const request = {
            sender: id,
            sdpOffer: offerSdp
        };
        sendMessageSignal("ReceiveVideoFrom", request)
    }

    const onIceCandidate = (id: any, candidate: any, wp: any) => {
        const request = {
            candidate: candidate,
            sessionId: id
        };
        sendMessageSignal("OnIceCandidate", request);
    }

    const startRecording = () => {
        sendMessageSignal("StartRecording", null);
    }

    const stopRecording = () => {
        sendMessageSignal("StopRecording", null);
    }

    const sendMessageSignal = (methodName: any, request: any) => {
        if (connection !== null) {
            if (request !== null) {
                connection.invoke(methodName, request);
            } else {
                connection.invoke(methodName);

            }
        }
    }

    const changeMicropphone = () => {
        if (mainUser) {
            const participant = participants[mainUser].rtcPeer;
            if (userMediaAudioStream) {
                if (microphone) {
                    participant.audioEnabled = false;
                } else {
                    participant.audioEnabled = true;
                }
                sendMessageSignal("ChangeMicrophone", {isMicOn: !microphone});
                participants[mainUser].isMicOn = !microphone;
                dispatch(setParticipants(participants));
                setMicrophone(!microphone);
            }
        }
    }
    
    const changeWhiteBoard = () => {
        if (!showWhiteBoard && hasSharing()) {
            stopSharing();
        }        
        sendMessageSignal("ChangeWhiteBoardVisibility", {visible: !showWhiteBoard});
        setShowWhiteBoard(!showWhiteBoard);
    }

    const changeCamera = () => {
        if (mainUser) {
            const participant = participants[mainUser].rtcPeer;
            if (userMediaVideoStream) {
                if (camera) {
                    participant.videoEnabled = false;
                } else {
                    participant.videoEnabled = true;
                }
                setCamera(!camera);
            }
        }
    }

    const onChangeCameraSettings = () => {
        if (userMediaVideoStream) {
                if (camera) {
                    //@ts-ignore
                    document.getElementById("camera").srcObject = null;
                } else {
                    //@ts-ignore
                    document.getElementById("camera").srcObject = userMediaVideoStream;
                }
            setCamera(!camera);
        }
    }

    const onChangeMicrophoneSettings = () => {
        if (userMediaAudioStream) {
            setMicrophone(!microphone);
        }
    }

    const invite = () => {
        setShowSettings(false);
        if (env().REACT_APP_USE_TM_AUTH) {
            setShowInviteModal(true);
        } else {
            const address = `${window.location.origin}${window.location.pathname}?roomId=${room}`;
            navigator.clipboard.writeText(address).then(() => {
                toast.success(`Адрес этой видеовстречи скопирован в буфер обмена: ${address}`);
            })
        }
    }

    const createJwtLink = () => {
        if (inviteUserName?.trim()?.length > 0) {
            setShowInviteModal(false);
            if (room) {
                requestsRepository.RoomApiService.createJwtLink({roomId: room, userName: inviteUserName}).then(res => {
                    if (res.success) {
                        setInviteUserName("");
                        navigator.clipboard.writeText(res.result).then(() => {
                            toast.success(`Пригласительная ссылка на эту видеовстречу скопирована в буфер обмена: ${res.result}`);
                        })
                    } else {
                        toast.error(res.message);
                    }
                });
            }
        }
    }

    const join = () => {
        if (userName?.trim()?.length > 0 && (camera || microphone)) {
            setShowUserInput(false);
            const request: any = {
                sessionId: token,
                roomId: room,
                userName: userName.trim(),
                isMicOn: microphone,
                isCamOn: !!userMediaVideoStream
            }
            sendMessageSignal("JoinRoom", request);
        }
    }

    const shareScreen = () => {
        navigator.mediaDevices.getDisplayMedia(
            {
                video: true,
                audio: false
            }
        ).then((res: any) => {
            res.getVideoTracks()[0].onended = () => {
                setCanStopSharing(false);
                sendMessageSignal("StopSharing", null);
            };
            setScreenStream(res);
            const request: any = {
                roomId: room,
                userName: "Экран"
            }
            sendMessageSignal("ShareScreen", request);
            setCanStopSharing(true);
        }).catch((error) => {
            console.warn(error);
        });
    }

    const stopSharing = () => {
        if (canStopSharing) {
            setCanStopSharing(false);
            sendMessageSignal("StopSharing", null);
        }
    }

    const hasSharing = () => {
        let screen = null;
        const keys = Object.keys(participants);
        keys.forEach((x: any) => {
            if (x === SCREEN_ID) {
                screen = participants[x];
            }
        });
        return screen;
    }

    const getParticipantsKeys = () => {
        return Object.keys(participants).filter((x: string) => x !== SCREEN_ID);
    }
    
    const changeCameraValue = (deviceId: any) => {
        userMediaVideoStream?.getTracks().forEach((track: any) => {
            track.stop();
        });
        setCameraValue(deviceId);
        navigator.mediaDevices?.getUserMedia({video: {deviceId}}).then(stream => {
            setUserMediaVideoStream(stream);
            const video: any = document.getElementById("camera");
            video.srcObject = stream;
            video.play();
            setCamera(true);
        }).catch((error) => {
            console.warn("Нет доступа к камере");
        });
    }
    
    const changeSettingsCameraValue = (deviceId: any) => {
        setSettingsCameraValue(deviceId);
        settingsUserMediaVideoStream?.getTracks().forEach((track: any) => {
            track.stop();
        });
        navigator.mediaDevices?.getUserMedia({video: {deviceId}}).then(stream => {
            setSettingsUserMediaVideoStream(stream);
            const video: any = document.getElementById("settings-video");
            video.srcObject = stream;
            video.play();
        }).catch(() => {
            console.warn("Нет доступа к камере")
        });
    }
    
    const chooseCameraDialog = () => {
        setShowCameraModal(true);
        setTimeout(() => changeSettingsCameraValue(cameraValue), 100);
    }
    
    const onChangeCamera = () => {
        setChangeCameraLoader(true);
        setCameraValue(settingsCameraValue);
        setUserMediaVideoStream(settingsUserMediaVideoStream);
        sendMessageSignal("LeaveRoom", null)

        for (let key in participants) {
            participants[key].rtcPeer.dispose();
        }
        dispatch(setParticipants({}));
        setMainUser(null);
        setTimeout(() => {
            joinByUser();
            setChangeCameraLoader(false);
            setShowCameraModal(false);
            }, 1100);
    }

    return (<div className="chat-container">
        {showRegister && <div className="join-container">
            <div className="register-text">Доступ к видеовстрече возможен только для авторизованных пользователей</div>
            <div onClick={() => window.location.href = env().REACT_APP_REGISTER_URL ?? ""}
                 className="register-btn">ВОЙТИ или зарегистрироваться
            </div>
        </div>}
        {showJoin && <div className="join-container">
            {timelimet ? <div className="timeout-container">
                    <div className="timeout-container__text">Видеосвязь была прервана автоматически
                        из-за превышения предела длительности. <br/> Для продолжения связи можно зайти в комнату заново.
                    </div>
                    <div className={`enter-button`}
                         onClick={() => register()}><SendIcon/>ВОЙТИ
                    </div>
                </div> :
                <div onClick={register} className="join-btn">
                    <CameraLoginIcon/>
                    <div className="join-btn__text">Начать видеовстречу</div>
            </div>}
        </div>}
        {showUserInput && 
            <div className="user-input-container">
                <video className="main-input-camer" id="camera" autoPlay playsInline={true}></video>
                {cameras?.length > 1 && <div className="choose-camera-container" style={{marginTop: 10}}>
                    <CustomSelect options={cameras} value={cameraValue} onChange={changeCameraValue} cyName={"change-camera-select"}/>
                </div>}
                <div className="buttons-container buttons-container_main-settings">
                    <div className={`main-chat-btns-container`}>
                        <div className={`chat-btn ${!microphone ? 'chat-btn_off' : ''}`}
                             onClick={onChangeMicrophoneSettings}>{microphone ? <MicIcon/> : <UnableMicIcon/>}</div>
                        <div className={`chat-btn ${!camera ? 'chat-btn_off' : ''}`} onClick={onChangeCameraSettings}>{camera ?
                            <CameraIcon/> : <CameraOffIcon/>}</div>
                    </div>
                </div>
                {!externalUserId && !env().REACT_APP_USE_TM_AUTH ? <>
                <div className="user-input-hint">Представьтесь, пожалуйста, чтобы присоединиться к встрече</div>
                <div className="user-input-wrapper">
                    <input value={userName} onKeyPress={event => {
                        if (event.charCode === 13) {
                            join();
                        }
                    }} onChange={(event) => setUserName(event.target.value)} type="text"
                           className="chat-input chat-input_white"/>
                    <div onClick={join}
                         className={`chat-send-btn chat-send-btn_name ${userName?.trim()?.length === 0 || (!camera && !microphone) ? 'chat-send-btn_disabled' : ''}`}>
                        <SendIcon color={userName?.trim()?.length === 0 || (!camera && !microphone) ? "#DADADA" : "#147DC1"}/>
                    </div>
                </div>
                </> : <div className={`enter-button ${!camera && !microphone ? 'enter-button_disabled' : ''}`} onClick={() => (camera || microphone) && joinByUser()}><SendIcon />ВОЙТИ</div>}
            </div>}
        {showWaitAdmin && <div className="message-container">
            <div className="message-container__text">{env().REACT_APP_WAIT_ADMIN_MESSAGE?.trim()?.length > 0 ? env().REACT_APP_WAIT_ADMIN_MESSAGE : "Ожидайте подключения администратора"}</div>
        </div>}
        {showRoom && <div style={{visibility: showWaitAdmin ? 'hidden' : 'unset'}} className="room-container">
            <div className="video-main-container">
                {hasSharing() && <div className="participants-container"><ParticipantItem
                    changeMain={(userId: any) => setMainUser(userId)} mainUser={mainUser} participant={hasSharing()}/>
                </div>}
                {showWhiteBoard && <WhiteBoard connection={connection} isAdmin={isAdmin} resize={showChat || showRecords}/>}
                {Array.from(Array(blocksCount).keys()).map(block =>
                    <div key={block}
                         className={`participants-container ${block !== showBlock - 1 || hasSharing() || showWhiteBoard ? 'participants-container_hidden' : ''}`}>
                        {getParticipantsKeys().splice(block * 9, 9).map((participantKey: any, key: any) =>
                            <ParticipantItem changeMain={(userId: any) => setSelectedUser(userId)} mainUser={selectedUser}
                                             key={key} participant={participants[participantKey]}/>)}
                    </div>
                )}
                {isRecording && <div className="recording-container">
                    <RecordingIcon/>
                    <div>Идёт запись</div>
                </div>}
                <div className="buttons-container buttons-container_call-settings">
                    <div className={`main-chat-btns-container`}>
                        <div className={`chat-btn ${!microphone ? 'chat-btn_off' : ''}`}
                             onClick={changeMicropphone}>{microphone ? <MicIcon/> : <UnableMicIcon/>}</div>
                        <div className={`chat-btn ${!camera ? 'chat-btn_off' : ''}`} onClick={changeCamera}>{camera ?
                            <CameraIcon/> : <CameraOffIcon/>}</div>
                        {env().REACT_APP_ENABLE_WHITE_BOARD && isAdmin && <div className={`chat-btn ${!showWhiteBoard ? 'chat-btn_off' : ''}`} onClick={changeWhiteBoard}><WhiteBoardIcon /></div>}
                        <div
                            className={`chat-btn ${!hasSharing() ? 'chat-btn_off' : !canStopSharing ? 'chat-btn_disabled' : ''}`}
                            onClick={() => hasSharing() ? stopSharing() : shareScreen()}><ShareIcon/></div>
                        <div className={`chat-btn ${!showChat ? 'chat-btn_off' : ''}`} onClick={() => {
                            setShowChat(!showChat);
                            setShowRecords(false)
                        }}><ChatIcon/>{newMessagesCount > 0 && <div className="chat-btn_messages-count ">{newMessagesCount}</div>}</div>
                    </div>
                    <ChatSettingsIcon ref={ref}
                                      className={`chat-btn chat-btn__settings ${showSettings ? 'chat-btn_off' : ''}`}
                                      onClick={() => setShowSettings(!showSettings)}>
                        {showSettings && <div className="chat-settings-container">
                            <div onClick={() => {
                                setShowSettings(false);
                                setShowRecords(true);
                                setShowChat(false);
                            }}>Показать записи
                            </div>
                            {(env().REACT_APP_USE_TM_AUTH ? isAdmin : true) && <div onClick={invite}>Пригласить участника</div>}
                            {!autoRecording && !isRecording && <div onClick={() => {
                                setShowSettings(false);
                                setCanStopRecording(true);
                                startRecording();
                            }}>Начать запись</div>}
                            {isRecording && canStopRecording && <div onClick={() => {
                                setShowSettings(false);
                                stopRecording();
                            }}>Остановить запись</div>}
                            {cameras?.length > 1 && <div onClick={() => chooseCameraDialog()}>Выбрать камеру</div>}
                        </div>}
                    </ChatSettingsIcon>
                    <div className={`chat-btn chat-btn__leave`} onClick={() => leaveRoom(false)}><PhoneIcon/></div>
                </div>
                {blocksCount > 1 && <div className="block-btns-container">
                    <div onClick={() => showBlock === 1 ? false : setShowBlock(showBlock - 1)}
                         className={`block-btn ${showBlock === 1 ? 'block-btn__disabled' : ''}`}><ArrowLeftIcon
                        color={showBlock === 1 ? '#6C6C6C' : '#fff'}/></div>
                    <div onClick={() => showBlock === blocksCount ? false : setShowBlock(showBlock + 1)}
                         className={`block-btn ${showBlock === blocksCount ? 'block-btn__disabled' : ''}`}>
                        <ArrowRightIcon color={showBlock === blocksCount ? '#6C6C6C' : '#fff'}/></div>
                </div>}
            </div>
            <div className={`messenger-container ${!showChat ? 'messenger-container_hidden' : ''}`}>
                <div className="messenger-header">
                    <div className="close-btn" onClick={() => setShowChat(false)}><CloseIcon/></div>
                </div>
                <div className="messenger-wrapper">
                    {mainUser &&
                        <Messenger visible={showChat} fullMode={false} userId={mainUser} authSessionId={token?.length === 0 ? null : token} serviceType={env().REACT_APP_SERVICE_TYPE}/>}
                </div>
            </div>
            <div className={`messenger-container ${!showRecords ? 'messenger-container_hidden' : ''}`}>
                <div className="messenger-header">
                    <div className="close-btn" onClick={() => setShowRecords(false)}><CloseIcon/></div>
                </div>
                <div className="records-wrapper">
                    <div className="records-title">ЗАПИСИ</div>
                    {records.filter((x: any) => x.endTime !== null).map((record: any, key: number) =>
                        <Link className="records-item"
                              key={key}
                              style={record.status === RecordStatus.Available ? {} : {pointerEvents : 'none'}}
                              to={`${env().REACT_APP_BACKEND_URL}/api/Kurento/file?name=${record.fileName}`}
                              // to={`${env().REACT_APP_ROOT_FOLDER?.length > 0 && env().REACT_APP_ROOT_FOLDER !== "/" ? `/${env().REACT_APP_ROOT_FOLDER}/` : env().REACT_APP_ROOT_FOLDER}?${routes.player}=true&fileName=${record.fileName}`} 
                              target="_blank">
                            <div>{formatDate(record.endTime, "HH:mm:ss, dd.MMM.yyyy")} ({getStattus(record.status)})</div>
                            <div
                                className="records-item__diff">{timeDiff(new Date(record.endTime), new Date(record.startTime))}</div>
                        </Link>
                    )}
                </div>
            </div>
            {showInviteModal && <div className="invite-modal-container">
                <div className="invite-modal">
                    <div>
                        <Input title="Имя пользователя" value={inviteUserName} onChange={setInviteUserName} onKeyPress={(event: any) => {
                            if (event.charCode === 13) {
                                createJwtLink()
                            }
                        }}/>
                    </div>
                    <div className="delete-message-btns-container">
                        <div className="delete-btn" onClick={() => createJwtLink()}>Сформировать ссылку</div>
                        <div className="cancel-btn" onClick={() => {
                            setShowInviteModal(false);
                            setInviteUserName("");
                        }}>Отменить
                        </div>
                    </div>
                </div>
            </div>}
            {showCameraModal && <div className="invite-modal-container">
                <div className="invite-modal">
                    <div>
                        <video style={{width: '100%'}} autoPlay={true} playsInline={true} controls={false} id={`settings-video`} />
                        <CustomSelect options={cameras} value={settingsCameraValue} onChange={changeSettingsCameraValue} cyName={"change-camera-settings-select"}/>
                    </div>
                    <div className="delete-message-btns-container">
                        <CustomButton
                            loading={changeCameraLoader}
                            onClick={() => onChangeCamera()}
                        >
                            Сохранить
                        </CustomButton>
                        <div className="cancel-btn" onClick={() => {
                            setShowCameraModal(false);
                        }}>Отменить
                        </div>
                    </div>
                </div>
            </div>}
        </div>}

    </div>);
}

