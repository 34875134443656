import { ChatMessage } from "../../api/models/ChatMessage";
import { ChatUser } from "../../api/models/ChatUser";
import { formatDate } from "../../utils/formatDate";
import { useRef, useState, useEffect } from "react";
import { useIntersectionObserver } from "usehooks-ts";
import { SettingsIcon } from "../icons/SettingsIcon";
import { FileIcon } from "../icons/FileIcon";
import { DeliveryIcon } from "../icons/DeliveryIcon";
import { RecievedIcon } from "../icons/RecievedIcon";
import { useDetectOutsideClick } from "../../hooks/useDetectOutsideClick";

interface IProps {
    message: ChatMessage;
    messages: ChatMessage[];
    unreadMessages: ChatMessage[];
    users: ChatUser[];
    showSettings: any;
    userId: any;
    visible: any;
    samePrevUser: any;
    sameNextUser: any;
    setReplyMessageId: (replyMessageId: any) => void;
    setShowSettings: (messageId: any) => void;
    setEditMessageId: (messageId: any) => void;
    deleteMessage: (messageId: any) => void;
    readMessage: (messageId: any, updateMessage: any) => void;
    setMessage: (messageText: any) => void;
    setAttachments: (attachments: any) => void;
    compact: any;
}

export const Messege = (props: IProps) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const entry = useIntersectionObserver(ref, {});
    const [showDelete, setShowDelete] = useState<any>(null);

    const { message, messages, samePrevUser, sameNextUser, visible, unreadMessages, users, showSettings, userId, setAttachments, 
        setMessage, setReplyMessageId, setShowSettings, setEditMessageId, deleteMessage, readMessage, compact } = props;
    
    useDetectOutsideClick(ref, () => setShowSettings(false));
    
    useEffect(() => {
        if (visible && !!entry?.isIntersecting && unreadMessages.find(x => x.id === message.id)) {
            readMessage(message.id, !message.isRead);
        }
    }, [entry, visible]);
    
    const getUser = (userId: any) => {
        return users.find(x => x.userId === userId);
    }

    const getMessage: any = (id: any) => {
        return messages.find(x => x.id === id);
    }
    
    const editMessage = () => {
        setShowSettings(null);
        setEditMessageId(message.id);
        setMessage(message.messageText);
        setAttachments(message.attachments);
    }
    
    const showDeleteMessage = () => {
        setShowSettings(null);
        setShowDelete(message.id);
    }
    
    const showReply = () => {
        setShowSettings(null);
        setReplyMessageId(message.id);
    }
    
    const onDeleteClick = () => {
        deleteMessage(message.id); 
        setShowDelete(null);
    }
    
    const onCancelDeleteClick = () => {
        setShowDelete(null);
    }

    return <div ref={ref} id={message.id} className={`message-wrapper${message.userId === userId ? ' reverse' : unreadMessages.findIndex(x => x.id === message.id) !== -1 ? ' unread' : ''}${sameNextUser ? ' message-wrapper_merge-messages' : ''}`}>
        <div className={`message-box-wrapper${compact ? ' message-box-wrapper_compact' : ''}`}>
        <div className={`message-box${samePrevUser ? ' message-box_merge-messages' : ''}`}>
            <div className={`message-box-header ${samePrevUser ? 'message-box-header_merge-messages' : ''}`}>
                {!samePrevUser && <div className="message-box-author">{getUser(message.userId)?.userName ?? "Гость"}</div>}
                <div style={{display: 'none'}} className="message-box-replay"
                     onClick={() => setReplyMessageId(message.id)}>Ответить
                </div>
            </div>
            <div className="message-box-main-container">
                <div className={`message-box-text ${samePrevUser ? 'message-box-text_hide-arrow' : ''}`}>
                    {message.replyMessageId !== null && <div className="message-box-wrapper message-box-wrapper-reply message-box-wrapper-reply_message"><div className="message-box">
                        <div className="message-box-header">
                            {getMessage(message.replyMessageId) === undefined ? <div>Сообщение удалено</div> :
                            <div
                                className="message-box-author">{getUser(getMessage(message.replyMessageId)?.userId)?.userName ?? "Гость"}</div>}
                        </div>
                        {getMessage(message.replyMessageId) !== undefined && <div className="message-box-text__data">
                            {getMessage(message.replyMessageId)?.messageText?.length > 0 ? getMessage(message.replyMessageId)?.messageText : ''} 
                            <div className="attachments-container">
                                {getMessage(message.replyMessageId)?.attachments?.map((attachment: any, key: number) => <div onClick={() => window.open(attachment.url, '_blank')} className="attachment-item" key={key}>
                                    <div className="attachment-item__text"><FileIcon />{attachment.fileName}</div>
                                </div>)}
                            </div>
                        </div>}
                    </div></div>}
                    {message.messageText?.length > 0 && <div className={`message-box-text__data${message.attachments?.length > 0 ? ' message-text-margin' : ''}`}>{message.messageText}</div>}
                    {message.userId === userId && <div className={`message-read-icon ${samePrevUser ? 'message-read-icon_merge-messages' : ''}`}>{message.isRead ? <RecievedIcon /> : <DeliveryIcon />}</div>}
                    <div className={`message-box-time ${samePrevUser ? 'message-box-time_merge-messages' : ''}`}>{formatDate(message.createdDate, "HH:mm")}</div>
                    {message.attachments?.filter(x => x.isImage).map((attachment, key: number) => <div onClick={() => window.open(attachment.url, '_blank')} className="message-attachment" key={key}>
                        <img src={attachment.url}  alt=""/>
                    </div>)}
                    {message.attachments?.filter(x => !x.isImage).map((attachment, key: number) => <div onClick={() => window.open(attachment.url, '_blank')} className="message-attachment" key={key}>
                        <FileIcon />
                        {attachment.fileName}
                        </div>)}
                </div>
                <SettingsIcon className={`message-box-settings ${samePrevUser ? 'message-box-settings_merge-messages' : ''}`} onClick={() => {
                    setShowSettings(showSettings === message.id ? null : message.id)
                }}>
                    <div style={{display: showSettings === message.id ? 'flex' : 'none'}}
                         className="message-box-settings-container">
                        {message.userId !== userId ?
                            <div className="message-box-settings-item outer-click-btn" onClick={showReply}>Ответить</div>
                            :
                            <>
                                <div className="message-box-settings-item outer-click-btn" onClick={editMessage}>Редактировать</div>
                                <div className="message-box-settings-item outer-click-btn" onClick={showDeleteMessage}>Удалить</div>
                            </>}
                    </div>
                    <div style={{display: showDelete === message.id ? 'flex' : 'none'}}
                         className="delete-message">
                        <div className="delete-message__title">Удалить сообщение?</div>
                        <div className="delete-message-btns-container">
                            <div className="delete-btn outer-click-btn" onClick={onDeleteClick}>Удалить</div>
                            <div className="cancel-btn outer-click-btn" onClick={onCancelDeleteClick}>Отменить</div>
                        </div>
                    </div>
                </SettingsIcon>
            </div>
        </div>
    </div></div>;
}